import React from "react";
import { Field, ErrorMessage } from "formik";
import CheckFormField from "components/input/check-form-field";
import SelectField from 'components/input/select-field';
import inputStyle from "./table-fields.module.scss";
// import { Link } from "react-router-dom";

interface IProps  {
  fields : Array<any>,
  disabled? : boolean
}
export const TextInput = ({ field, ...props } : any) => {
  //

  return <input {...field} {...props} />;
};
export const ErrorMsg = (msg: string, className? : string) => (
  <div className={`${inputStyle.validation_error} ${className}`}>{msg}</div>
);

const renderOpts = (opts: Array<{label: string, value: string}>) => {
  
  const options = opts.map((opt, index) => (
    <option key={index} value={opt.value}>{opt.label}</option>
  ));
  options.unshift(
    <option key="default" value="" disabled selected>
      --Wybierz--
    </option>
  );
  return options;
};

const RadioInput = ({ field, ...props }: any) => {
  return props.options.map((item: {id: string, label: string, checked? : boolean }, index: string) => {
    return (
      <div className="box p1-box p1-W-box first" key={index}>
        <input
          checked={item.checked}
          type={props.type}
          name={props.name}
          id={item.id}
        />
        <label htmlFor={item.id}>{item.label}</label>
      </div>
    );
  });
};

// const handleBlur = (props) => {
//

//   props.onBlur(this.props.id, true);
// };

export const renderCheckbox = (field: any) => {
  return field.options.map((opt : any, index: number) => {
    const fieldProps = {

      name: field.name,
      ...opt,
      orientation: "vertical",
      onCheckChange: field.onCheckChange,
    };
    return <CheckFormField key={index} {...fieldProps} />;
  });
};

function renderFields({ fields, disabled }: IProps){
  const result = fields.map((item: any, index: number) => {
    return (
      <tr key={index} className="p5-row">
        <td className={`caption ${item.required && "mandatory"}`}>
          {item.label}
        </td>
        <td className="value">
          {item.type == "select"  && (<>
            <Field data-testid="select-field" as="select" {...item} placeholder="--wybierz--">
              {renderOpts(item.options)}
            </Field>
            <ErrorMessage name={item.name} render={ErrorMsg} />
            </>
          )}
          {item.type == "select-autocomplete"  && (
            <SelectField  {...item}/>
          )}
          {item.type == "radio" && (
            <div className="radiolist p1-radiolist" id="p1-radiolist">
              <Field as={RadioInput} {...item} />
            </div>
          )}

          {item.type == "checkbox" && (
            <div className="vertical">
              {renderCheckbox(item)}
              <ErrorMessage name={item.name} render={ErrorMsg} />
            </div>
          )}
          {(item.type == "text" ||
            item.type == "number" ||
            item.type == "date" ||
            item.type == "tel") && [
            <Field
            key={1}
              className="text"
              data-testid="text-field"
              as={TextInput}
              {...item}
              disabled={disabled || item.disabled}
            ></Field>,
            <ErrorMessage key={2} name={item.name} render={ErrorMsg} />,
          ]}
        </td>
      </tr>
    );
  });


  return <>{result}</>
};

export default renderFields;

